import { useEffect } from 'react';
import { Page } from '@adobe/magento-storefront-events-sdk/dist/types/types/schemas';
import { handleActions } from '@/integrations/adobe-product-recs/utils/handleActions';

export const usePageView = ({ pageType }: { pageType: string }) => {
    useEffect(() => {
        handleActions(() => {
            if (!globalThis.window.magentoStorefrontEvents) return;

            const pageContext: Page = {
                maxXOffset: 0,
                maxYOffset: 0,
                minXOffset: 0,
                minYOffset: 0,
                pageType,
                ping_interval: 0,
                pings: 0,
            };
            globalThis.window.magentoStorefrontEvents.context.setPage(pageContext);
            globalThis.window.magentoStorefrontEvents.publish.pageView();
        });
    }, [pageType]);
};
