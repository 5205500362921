export const handleActions = (fallback: () => void) => {
    if (globalThis.window.magentoStorefrontEvents) {
        fallback();
    } else {
        if (!globalThis.window.magentoStorefrontEventsQueue) {
            globalThis.window.magentoStorefrontEventsQueue = [];
        }
        globalThis.window.magentoStorefrontEventsQueue.push(fallback);
    }
};
