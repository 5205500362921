import { findMediaGalleryEntriesWithFallback } from './findMediaGalleryEntriesWithFallback';
import { isProductConfigurableAndHasOptions } from './isProductConfigurableAndHasOptions';
import type { MediaGalleryEntry, SwatchOptions } from '@/types/product';
import { addPrefixInFilePath } from '@/utils/Product';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';

const sortByPosition = (collection: MediaGalleryEntry[]): MediaGalleryEntry[] =>
    (collection || []).slice().sort((currentValue, nextValue) => currentValue.position - nextValue.position);

export const getMediaGalleryEntries = (
    product: TParsedProductForContext,
    optionCodes: SwatchOptions['optionCodes'],
    optionSelections: SwatchOptions['optionSelections'],
): MediaGalleryEntry[] => {
    const { media_gallery_entries = [], variants = [] } = product || {};
    const isConfigurable = isProductConfigurableAndHasOptions(product);

    if (!isConfigurable || !optionSelections || ![...optionSelections.values()].some(Boolean)) {
        return media_gallery_entries;
    }

    const variantMediaGalleryEntries = findMediaGalleryEntriesWithFallback({
        optionCodes,
        optionSelections,
        variants,
    });

    return [
        ...sortByPosition(addPrefixInFilePath(variantMediaGalleryEntries)),
        ...sortByPosition(addPrefixInFilePath(media_gallery_entries)),
    ];
};
