import { useEffect } from 'react';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';
import { Product } from '@adobe/magento-storefront-events-sdk/dist/types/types/schemas';
import { handleActions } from '@/integrations/adobe-product-recs/utils/handleActions';
import { usePageView } from '@/integrations/adobe-product-recs/hooks/usePageView';

export const useProductPageView = ({ product }: { product: TParsedProductForContext }) => {
    usePageView({ pageType: 'product' });

    useEffect(() => {
        handleActions(() => {
            if (!globalThis.window.magentoStorefrontEvents) return;

            const productContext: Product = {
                // This is required in most cases and will be mandatory in future releases.
                categories: product.categories.map((category) => category.id.toString()),
                name: product.name,
                productId: product.id,
                sku: product.sku,
            };
            globalThis.window.magentoStorefrontEvents.context.setProduct(productContext);
            globalThis.window.magentoStorefrontEvents.publish.productPageView();
        });
    }, [product.id]);
};
