import { useEffect, useMemo, useState } from 'react';
import { useLocale } from 'next-intl';
import { hasMatchingAttributes } from '@/components/RootComponents/Product/utils/hasMatchingAttributes';
import { productHasDiscount } from '@/components/RootComponents/Product/utils/productHasDiscount';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import { UNCONSTRAINED_SIZE_KEY } from '@/components/OptimizedImage/utils/helpers';
import { generateImageProps } from '@/components/OptimizedImage/utils/generateImageProps';
import { transparentPlaceholder1x1 } from '@/components/OptimizedImage/utils/images';
import type {
    ConfigurableAttributeOption,
    ConfigurableProductOptions,
    ConfigurableVariant,
    PriceRange,
} from '@/types/product';
import type { IUseProductCard } from '../types';
import { ImageWidthsEnum } from '@/components/OptimizedImage/types';
import { useAppContext } from '@/lib/context';

const OUT_OF_STOCK_CODE = 'OUT_OF_STOCK';
const IMAGE_SIZE_SWATCHES = 44;
export const IMAGE_SIZE = 555;
// This is for SEO Image, if the image is to be changed to resource one, please update the values here accordingly
export const IMAGE_WIDTHS = new Map().set(767, 45).set(1799, 30).set(UNCONSTRAINED_SIZE_KEY, IMAGE_SIZE);
export const SIZES_NEW_GRID = new Map()
    .set(ImageWidthsEnum.REGULAR, ImageWidthsEnum.REGULAR)
    .set(IMAGE_SIZE, IMAGE_SIZE);
const LIGHT_COLORS = ['white', 'beige', 'yellow'];

const getOptionsByCode = <T>(array: Array<T & Record<string, any>>, code: string) =>
    array.find((i) => (i.attribute_code || i.code) === code) as T;

const findMatchingVariant = (value: number, options: ConfigurableVariant[]) => {
    if (value && options?.length) {
        return options.find(
            ({ attributes, product }) =>
                product.stock_status !== OUT_OF_STOCK_CODE && hasMatchingAttributes(attributes, value),
        );
    }
};

const findMatchingVariantWithLowestPrice = (value: number, options: ConfigurableVariant[]) => {
    if (!value || !options?.length) return null;

    return options.reduce((lowestPriceOption: null | ConfigurableVariant, option) => {
        const { attributes, product } = option;
        const finalPrice = product.price_range.minimum_price.final_price.value;

        if (product.stock_status !== OUT_OF_STOCK_CODE && hasMatchingAttributes(attributes, value)) {
            if (
                !lowestPriceOption ||
                finalPrice < lowestPriceOption.product.price_range.minimum_price.final_price.value
            ) {
                return option;
            }
        }

        return lowestPriceOption;
    }, null);
};

export const getPriceFromRange = (
    priceRange: PriceRange,
    selectedOption: number | null,
    defaultCurrency?: string | null | undefined,
) => {
    const { maximum_price, minimum_price } = priceRange || {};
    const { discount, final_price, regular_price } = minimum_price || {};
    const { currency = defaultCurrency || '', value } = regular_price || {};
    const maxRegularPrice = maximum_price?.regular_price.value;
    const maxDiscountAmount = maximum_price?.discount?.amount_off;

    const defaultPrice = priceRange?.maximum_price?.final_price?.value || maxRegularPrice;

    return {
        currency,
        hasDiscount: selectedOption
            ? !!discount?.amount_off
            : !!discount?.amount_off && maxDiscountAmount === discount?.amount_off,
        maxPrice: maxRegularPrice ? Math.max(value, maxRegularPrice) : defaultPrice,
        minPrice: final_price?.value ? Math.min(value, final_price.value) : defaultPrice,
        value,
    };
};

const convertSwatchData = (
    name: string,
    image: string,
    active: boolean = true,
    hasDiscount: boolean = false,
    isLightColor: boolean = false,
    handleChangeSwatch: (e: Event) => void,
) => {
    return {
        active,
        hasDiscount,
        height: IMAGE_SIZE_SWATCHES,
        image,
        isLightColor,
        isSmall: true,
        label: name,
        onClick: handleChangeSwatch,
        onFocus: handleChangeSwatch,
        width: IMAGE_SIZE_SWATCHES,
    };
};

export const getPrice = (selectedOption: number | null, price: PriceRange, options: ConfigurableVariant[]) => {
    const item = (selectedOption && options && findMatchingVariantWithLowestPrice(selectedOption, options)) || null;

    return getPriceFromRange(item?.product?.price_range || price, selectedOption);
};

export const useProductCard = ({ changePagination, isBot, item, itemRef, setInViewport }: IUseProductCard) => {
    const [{ storeConfig }] = useAppContext();
    const locale = useLocale();
    const resourceUrl = useResourceUrl();

    const { color, configurable_options, id, image, name, price_range, small_image, url_key, url_suffix, variants } =
        item;

    const [swatchIndex, setSwatchIndex] = useState<null | number>(null);
    const [showRangePrice, setShowRangePrice] = useState(true);
    const findImageUrl = (image: { url?: string } | null) => image?.url || image || '';
    const thumbnail = findImageUrl(image) || findImageUrl(small_image);
    const productLink = resourceUrl(`/${url_key}${url_suffix || ''}`);
    const productLinkParams = `${swatchIndex ? '?color=' + swatchIndex : color ? '?color=' + color : ''}`;

    const productOptions = useMemo(() => {
        if (!configurable_options?.length || !variants?.length) {
            return;
        }

        const { values } = getOptionsByCode<ConfigurableProductOptions>(configurable_options, 'color');

        const newValues = values.reduce((array: Array<any>, value) => {
            const { value_index } = value;
            const findMatch = findMatchingVariant(value_index, variants);

            if (!findMatch) {
                return array;
            }

            const attributes = getOptionsByCode<ConfigurableAttributeOption>(findMatch.attributes, 'color');
            const { media_gallery, thumbnail: thumbnailSwatch } = findMatch.product;
            const { swatch_data, ...restProductFields } = value;

            const item = {
                ...restProductFields,
                activeColorItem: parseFloat(color),
                color: swatch_data
                    ? swatch_data.thumbnail
                    : thumbnailSwatch?.url ||
                      media_gallery?.find((mediaItem: any) => mediaItem.__typename === 'ProductImage')?.url ||
                      thumbnail,
                colorGroup: attributes?.color_group || '',
                hasColor: !!swatch_data?.value,
                hasDiscount: productHasDiscount(variants, value_index),
                id: value.value_index,
                image:
                    thumbnailSwatch?.url ||
                    media_gallery?.find((mediaItem: any) => mediaItem.__typename === 'ProductImage')?.url ||
                    thumbnail,
                mainImage:
                    media_gallery?.find((mediaItem: any) => mediaItem.__typename === 'ProductImage')?.url || thumbnail,
            };

            return [...array, item];
        }, []);

        return newValues;
    }, [item, configurable_options, thumbnail, variants]);

    useEffect(() => {
        if (!itemRef?.current) return;

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.01,
        };

        const callback = (entries: any[]) => {
            entries.forEach((entry: any) => {
                const { boundingClientRect, intersectionRatio, isIntersecting, target } = entry;
                const last = target.getAttribute('data-last');
                const pageIndex = Number(target.getAttribute('data-page-index'));

                if (isIntersecting && last === 'true' && intersectionRatio < 0.5 && !!pageIndex) {
                    if (boundingClientRect.top < 0) {
                        setInViewport(false);
                        changePagination(pageIndex);
                    } else {
                        setInViewport(true);
                        changePagination(pageIndex + 1);
                    }
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        observer.observe(itemRef.current);

        return () => observer.disconnect();
    }, [changePagination, itemRef]);

    const productThumbnail = useMemo(() => {
        const variant =
            swatchIndex &&
            productOptions?.find?.(({ value_index }: { value_index: number }) => value_index === swatchIndex);

        return variant?.image || thumbnail;
    }, [swatchIndex, productOptions, thumbnail]);

    const productPrices = {
        ...getPrice(swatchIndex, price_range, variants),
        location: locale.startsWith('en') ? 'en-US' : locale,
        pattern: !locale.startsWith('en') && storeConfig.patternPrice,
    };

    // We want to serve bots with the canonical URL to PDP on main image
    // And still use URL with color params on swatches in bot
    const canonicalPathProps = `${productLink}${!isBot ? productLinkParams : ''}`;

    const handleChangeSwatch = (event: Event, id: number) => {
        event.preventDefault();
        setSwatchIndex(id);
        setShowRangePrice(false);
    };

    const priceIsSame = (options: ConfigurableVariant[]) => {
        if (!options?.length) return false;

        const firstFinalPrice = options[0].product.price_range.minimum_price.final_price.value;

        return options.every(
            (option) => option.product.price_range.minimum_price.final_price.value === firstFinalPrice,
        );
    };

    const swatchesProps = productOptions?.map((item: any) => ({
        ...convertSwatchData(
            item.label,
            item.color,
            item.id === (swatchIndex || item.activeColorItem),
            item.hasDiscount,
            LIGHT_COLORS.includes(item.colorGroup?.toLowerCase()),
            (event: Event) => handleChangeSwatch(event, item.id),
        ),
    })) || [convertSwatchData(name, productThumbnail, true, false, false, (event) => handleChangeSwatch(event, id))];

    const imageProps = generateImageProps({
        height: IMAGE_SIZE,
        isOptimizedByFastly: true,
        sizesSet: IMAGE_WIDTHS,
        src: productThumbnail,
        width: IMAGE_SIZE,
        widths: SIZES_NEW_GRID,
    });

    return {
        canonicalPathProps,
        imageProps: {
            ...imageProps,
            alt: name,
            fileReference: imageProps.src || '',
            placeholder: transparentPlaceholder1x1,
            url: canonicalPathProps,
        },
        productDetails: {
            badgeList: item.badgesList,
            id,
            linkProps: { scroll: true },
            name,
            urlKey: canonicalPathProps,
        },
        productOptions,
        productPrices,
        productThumbnail,
        showRange: productOptions?.length === 1 || priceIsSame(item?.variants) ? false : showRangePrice,
        swatchesProps,
    };
};
